<template>
  <div>
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Partner Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="otherPayment.PartnerCode" />
      </div>
    </div>
    <br />
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Partner Name</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="otherPayment.PartnerName" />
      </div>
    </div>
    
    <br />
    <div class="vx-row margin-btm" style="width: 100%">
      <!-- IF TOTAL SELECTED < AMOUNT v-if="totalSelected <= otherPayment.Amount" -->
       <vs-alert
        v-if="remaining == 0"
        color="success"
        class="mt-3"
        icon-pack="feather"
        close-icon="icon-x"
      >
        Total Amount : {{ priceFormat(otherPayment.Amount) }}
        <hr />
        Total Amount Selected : {{ priceFormat(totalSelected) }}
        <hr />
         Additional Value : {{ priceFormat(totalAdditionalValue) }}
        <hr />
        Remaining : {{ formatPrice(remaining) }}
        <!-- Remaining :  <template v-if="remaining<0">-</template>{{ priceFormat(remaining) }} -->
      </vs-alert>
      <!-- IF TOTAL SELECTED > AMOUNT -->
      <vs-alert
            v-else
            color="warning"
            class="mt-3"
            icon-pack="feather"
            close-icon="icon-x"
          >
            Total Amount : {{ priceFormat(otherPayment.Amount) }}
            <hr />
            Total Amount Selected : {{ priceFormat(totalSelected) }}
            <hr />
            Additional Value : {{ priceFormat(totalAdditionalValue) }}
            <hr />
            Remaining :  {{  formatPrice(remaining) }}
          </vs-alert> 
    </div>


    <div style="width: 100%">
      <div>
        <!-- <vs-button
				v-on:click="doPaymentApproval()"
				color="primary"
				icon-pack="feather"
				icon="icon-inbox"
				>Approval</vs-button
			> -->
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="
                  p-3
                  border border-solid
                  d-theme-border-grey-light
                  rounded-full
                  d-theme-dark-bg
                  cursor-pointer
                  flex
                  items-center
                  justify-between
                  font-medium
                "
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th width="5%"></vs-th>
            <vs-th>Supplier</vs-th>
            <vs-th sort-key="code">DN Info</vs-th>
            <vs-th sort-key="code">Paid Now</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              :class="data[indextr].class"
            >
              <vs-td>
                <vs-checkbox
                  v-on:click="check(tr.dn_id, indextr)"
                  :checked="checked.includes(tr.dn_id)"
                >
                </vs-checkbox>
              </vs-td>
              <vs-td :data="data[indextr].supplier_name">
                <div class="vx-row ml-0">
                  <span class="w-5/5"
                    >{{ data[indextr].supplier_code }} -
                    {{ data[indextr].supplier_name }}</span
                  >
                </div>
                <div class="vx-row ml-0">
                  <span class="w-5/5"
                    >{{ data[indextr].supplier_address }},
                    {{ data[indextr].supplier_city }} ({{
                      data[indextr].supplier_contact_name
                    }}
                    - {{ data[indextr].supplier_phone }})</span
                  >
                </div>
              </vs-td>
              <vs-td :data="data[indextr].code">
                <div class="vx-row ml-0">
                  DN Code : {{ tr.dn_code }}<br />
                  DN Type : {{ tr.type }}<br />
                  DN Value : {{ priceFormat(tr.total_dn) }}<br />
                  DN Unpaid : {{ tr.unpaid_value }}                  
                </div>
              </vs-td>
              <vs-td style="text-align: right">
                <template v-if="checked[checked.length - 1] == tr.dn_id">
                  <vs-input
                    class="w-full"
                    @keypress="isNumber($event)"
                    v-model="dataChecked[checked.length - 1].paid_now"
                    @keyup="handlePrice(checked.length - 1)"
                  />
                </template>
                <template v-else-if="checked.includes(tr.dn_id)">
                  {{ tr.paid_now }}
                </template>
                <template v-else> 0 </template>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
    </div>
    <br />

    <div class="vx-row mb-2" v-for="(input,k) in inputs" :key="k">

    <div class="vx-col w-1/4">
        <label class="vs-input--label">COA</label>
        <multiselect
          class="selectExample"
          v-model="input.coa_id"
          :options="optionCoa"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="Code"
          label="Code"
          :disabled="disabled == true"
        
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </div>
          </template>
        </multiselect>
        <!-- <span
        class="text-danger text-sm"
        v-if="coa_ == null"
        >This field is required</span
      > -->
      </div>

      <div class="vx-col w-1/4">
        <label class="vs-input--label">Cost Center</label>
        <multiselect
          class="selectExample"
          v-model="input.cost_center_id"
          :options="optionCostCenter"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="Name"
          label="Name"
          :disabled="disabled == true"
      
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </div>
          </template>
        </multiselect>
        <!-- <span
        class="text-danger text-sm"
        v-if="costCenter == null && status_submit"
        >This field is required</span
      > -->
      </div>

      <div class="vx-col w-1/4">
        <label class="vs-input--label">Value</label>
        <!--@keypress="isNumber($event)" -->
        <vs-input
                      class="w-full"
                      type ="number"
                    
                      v-model="input.additional_value"
                      @change="totalAdditionalValue"
                    />
        <!-- <vs-input /> -->
              
    
      </div>

      <div class="vx-col w-1/4">
        <label class="vs-input--label">Operating Unit</label>
        <multiselect
          class="selectExample"
          v-model="input.ou_id"
          :options="optionOU"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Check DN first"
          track-by="Name"
          label="Name"
          :disabled="disabled == true"
          @input="onChangeOU(input.ou_id, k)"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.territory.code }} - {{ dt.option.territory.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.territory.code }} - {{ dt.option.territory.name }}</span>
            </div>
          </template>
        </multiselect>
        <!-- <span
        class="text-danger text-sm"
        v-if="costCenter == null && status_submit"
        >This field is required</span
      > -->
        <div class="whitespace-no-wrap">
          <div class="mt-4 flex mr-1">
            <vs-button style="margin-right: 5px;" size="small" @click="removeField(k)" icon-pack="feather" icon="icon-minus" v-show="k || (!k && inputs.length > 1)">
            </vs-button>
            <vs-button style="margin-right: 5px;" size="small" @click="addField(k)" icon-pack="feather" icon="icon-plus" v-show="k == inputs.length - 1">
            </vs-button>
          </div>
        </div>
      </div>
    </div>
      
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>
  <br />      
      <vs-button @click="handleSubmit()" icon-pack="feather" icon="icon-save">
        Submit
      </vs-button>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import {
  dataInvoiceCustomer,
  postPaymentLine,
} from "../../../../services/api/payment";
import moment from "moment";
export default {
  props: {
    otherPayment: Object,
  },
  data() {
    return {
      dataPayment: [],
      checkedAll: false,
      checked: [],
      dataChecked: [],
      allOptionOU: [],
      optionOU: [],
      additionalValue: 0,
      costCenter: null,
      coa: null,
      optionCostCenter: [],
      optionCoa: [],
      table: {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "asc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100],
        start: 1,
        end: 0,
      },
      inputs: [{
        additional_value: 0,
        cost_center_id: null,
        coa_id: null,
        ou_id: null,
      }],
      additionalCoa: [{
        additional_value: 0,
        cost_center_id: null,
        coa_id: null,
        ou_id: null,
      }],
    };
  },
  components: {
    Datepicker
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    totalSelected: function () {
      console.log("value selected Changed")
      let total = this.dataChecked.reduce(
        (acc, value) =>
          acc +
          parseFloat(value.paid_now.toString().replace(/,/g, "").toString()),
        0
      );
      // if (isNaN(total)) {
      //   this.dataChecked.map((v) => {
      //     console.log(v.paid_now);
      //     if (isNaN(v.paid_now) || v.paid_now == "") {
      //       v.paid_now = 0;
      //     }
      //   });
      //   return 0;
      // }
      return total;
    },
    totalAdditionalValue : function () {
      console.log("valueChanged")
      let totalValue = 0;
      if (this.inputs.length > 1) {
        for (let i = 0; i < this.inputs.length; i++) {
          let totalVal = 0;
          totalVal = this.inputs[i].additional_value == undefined ? 0 : parseFloat(this.inputs[i].additional_value);
          totalValue = totalValue + totalVal;
        }
      } else {
        totalValue = this.inputs[0].additional_value == undefined ? 0 : parseFloat(this.inputs[0].additional_value);
      }
      console.log("totalValue", totalValue)
      return totalValue;
    },

    remaining: function () {
      let remaining = 0;
      remaining = this.otherPayment.Amount - this.totalSelected + this.totalAdditionalValue;
      remaining = parseFloat(remaining).toFixed(2);
      console.log("remaining", remaining)
      return remaining;
      
    },
  },
  mounted() {
    console.log(this.payment);
    this.getData();
    this.getOptionCostCenter();
    this.getOptionCoa();
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handlePrice(index) {
      let paid = this.dataChecked[index].paid_now.toString().replace(/,/g, "");
      if (paid == "") {
        this.dataChecked[index].paid_now = 0;
      }
      let unpaid = this.dataChecked[index].unpaid_value
        .toString()
        .replace(/,/g, "");
      if (unpaid == "") {
        this.dataChecked[index].paid_now = 0;
      }
      // console.log("ini paid:",paid);
      if (isNaN(paid) || paid === "" || paid === null) {
        paid = 0; // Update the local paid variable
      }
      console.log(this.dataChecked[index]);
      if (parseFloat(unpaid) < parseFloat(paid)) {
        paid = this.dataChecked[index].unpaid_value;
      }
      this.dataChecked[index].paid_now = this.formatPrice(paid);
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    check(val, index) {
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
        this.dataChecked = this.dataChecked.filter((v) => {
          return v.dn_id != val;
        });
        this.optionOU = this.dataChecked.filter((v) => {
          return v.ID != val;
        });
      } else {
        this.checked.push(val);

        // this.table.data[index].paid_now = this.table.data[index].dn_value - this.table.data[index].used_dn_value
        this.dataChecked.push(this.table.data[index]);
        if (!this.optionOU.some(item => item.territory.ID === this.table.data[index].territory.ID)) {
          this.optionOU.push(this.table.data[index]);
        }
        console.log("this.dataChecked", this.dataChecked);
        console.log("this.checked", this.checked);
      }
    },
    onChangeOU(val, index) {
      if (this.allOptionOU.length == 0) {
        this.allOptionOU = this.optionOU;
        console.log("this.allOptionOU", this.allOptionOU);
      }
      
      console.log("Val: ", val);
      if (this.optionOU.includes(val)) {
        console.log("this.optionOU", this.optionOU);
        this.optionOU.splice(this.optionOU.indexOf(val), 1);
      }
    },
    // paramData() {
    //   return {
    //     debit_note_id: this.debitNote.selected.dn_id,
    //     cost_center_id: this.costCenter.ID,
    //     chart_of_account_id: this.coa.ID,
    //     adjustment_value: parseFloat(this.adjustment_value.replace(/,/g, '')),
    //     control_value: parseFloat(this.control_value.replace(/,/g, '')),
    //     remaining_value: parseFloat(this.remaining_value.replace(/,/g, '')),
    //     posting_date: moment(this.posting_date).format('YYYY-MM-DD'),
    //     reason: this.reason,
    //     note: this.note,
    //     file: this.paramsUpload,
    //   };
    // },
    getOptionCoa() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/chart-of-account", {
          params: {
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            var _this = this
            resp.data.records.forEach(function(e){
              _this.optionCoa.push(e.coa);
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Coa option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionCostCenter() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/cost-center", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionCostCenter = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Cost Center option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    getData() {
      this.$vs.loading();
      this.setCoaSelected(this.ChartOfAccountID);
      // this.setCostCenterSelected(this.CostCenterID);
      this.$http
        .get("/api/v1/debit-note/dn", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_id: this.otherPayment.TerritoryID,
            territory_area_id: this.otherPayment.TerritoryAreaID,
            status: 1,
            supplier_id: this.otherPayment.PartnerID,
            settlement_method: "Payment",
            type: "payment-for-dn",
    
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_records;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_seardh;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = [];
            this.data = resp.data.records;
            resp.data.records.map((row) => {
              this.table.data.push({
                ...row,
                ...{
                  paid_now: this.priceFormat(row.total_dn - row.used_dn_value),
                  unpaid_value: this.priceFormat(
                    row.total_dn - row.used_dn_value
                  ),
                },
              });
            });

            this.setStartEnd();
            this.$vs.loading.close();
            console.log(this.table.data.dn_id);
          }
        });
    },
    addChecked(val, index) {
      console.log(this.dataChecked, "check");
      console.log(this.checked.length, "leng");
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
        this.dataChecked = this.dataChecked.filter((v) => {
          return v.ID != val;
        });
        this.optionOU = this.dataChecked.filter((v) => {
          return v.ID != val;
        });
      } else {
        this.checked.push(val);
        this.dataChecked.push(this.dataPayment[index]);
        if (!this.optionOU.some(item => item.territory.ID === this.table.data[index].territory.ID)) {
          this.optionOU.push(this.table.data[index]);
        }
        console.log("this.dataChecked", this.dataChecked);
        console.log("this.checked", this.checked);
      }
    },
    handleSubmit() {
      
      // console.log(this.dataChecked);
      // let lines = []
      // this.dataChecked.map((row, index) => {
      //   lines.push({
      //     paid: parseFloat(row.paid_now.toString().replace(/[^,\d]/g, "").toString()),
      //     debit_note_id: row.dn_id
      //   })
      // });
      // const params = {
      //   other_payment_id: this.otherPayment.ID,
      //   line: lines,
      // };
      // console.log(params,"params")

      console.log("coa", this.coa);
      // console.log("coa_", this.coa_);
      console.log("CostCenter", this.costCenter);
      // console.log("coa ID", this.coa.ID);
      // console.log("CostCenter ID", this.costCenter.ID);
      // console.log("CostCenter_", this.costCenter_);

      const checkPaidZero = this.dataChecked.filter((row) => {
        return row.paid_now == 0;
      });
      if (checkPaidZero.length > 0) {
        this.$vs.notify({
          title: "Failed",
          text: "Selected paid now must greater than 0!",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return false;
      }

      if (this.dataChecked.length < 1) {
        this.$vs.notify({
          title: "Failed",
          text: "Please choose one invoice",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return false;
      }

      for (let i = 0; i < this.inputs.length; i++) {
        // if (this.dataChecked[0].type != "Return") {
          console.log("remaining: ", this.remaining);
          if (this.remaining != 0) {
            if (this.inputs[i].coa_id == 0 || this.inputs[i].coa_id == undefined || this.inputs[i].coa_id == null || this.inputs[i].coa_id == "") {
              this.$vs.notify({
                title: "Failed",
                text: "COA required",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-alert-circle",
                color: "warning",
              });
              return false;
            }
            
            if (this.inputs[i].cost_center_id == 0 || this.inputs[i].cost_center_id == undefined || this.inputs[i].cost_center_id == null || this.inputs[i].cost_center_id == "") {
              this.$vs.notify({
                title: "Failed",
                text: "Cost Center required",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-alert-circle",
                color: "warning",
              });
              return false;
            }
          }
        // }
        
      }

      let additionalCoa = null;
      
      for (let i = 0; i < this.dataChecked.length; i++) {
        console.log("this.dataChecked[i].type", this.dataChecked[i].type);
        if (this.dataChecked[i].type == "Return") {
          console.log("this.dataChecked[i].type 1", this.dataChecked[i].type);
          for (let i = 0; i < this.inputs.length; i++) {
            if (this.remaining != 0) {
              if (this.inputs[i].ou_id == 0 || this.inputs[i].ou_id == undefined || this.inputs[i].ou_id == null || this.inputs[i].ou_id == "") {
                this.$vs.notify({
                  title: "Failed",
                  text: "Operating Unit required",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-alert-circle",
                  color: "warning",
                });
                return false;
              }
  
              console.log("this.inputs[i].additional_value", this.inputs[i].additional_value);
              if (this.inputs[i].additional_value != 0) {
                console.log("this.inputs:this.dataChecked[i].type) ", this.inputs);
                this.additionalCoa[i].additional_value = parseFloat(this.inputs[i].additional_value);
                this.additionalCoa[i].coa_id = this.inputs[i].coa_id.ID;
                this.additionalCoa[i].cost_center_id = this.inputs[i].cost_center_id.ID;
                this.additionalCoa[i].ou_id = this.inputs[i].ou_id.territory.ID;
  
                additionalCoa = this.additionalCoa;
  
                console.log("COA required");
                
                // return true;
              }
            }
          }
        } else {
          if (this.inputs[0].additional_value != 0) {
            console.log("this.inputs: ", this.inputs);
            for (let i = 0; i < this.inputs.length; i++) {
              this.additionalCoa[i].additional_value = parseFloat(this.inputs[i].additional_value);
              this.additionalCoa[i].coa_id = this.inputs[i].coa_id.ID;
              this.additionalCoa[i].cost_center_id = this.inputs[i].cost_center_id.ID;
              this.additionalCoa[i].ou_id = 0;
            }

            additionalCoa = this.additionalCoa;
            // return true;
          }
        }
      }

      if (this.remaining != 0) {
        this.$vs.notify({
          title: "Failed",
          text: "Remaining must be 0!",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return false;
      }
      
      this.$vs.loading();
      let lines = [];
      this.dataChecked.map((row, index) => {
        lines.push({
          paid: parseFloat(
            row.paid_now
              .toString()
              .replace(/,/g, "")
              .toString()
          ),
          debit_note_id: row.dn_id,
        });
      });
      const params = {
        other_payment_id: this.otherPayment.ID,
        line: lines,
        // coa_id: this.coa == null ? 0 : this.coa.ID ,
        // cost_center_id: this.costCenter == null ? 0 : this.costCenter.ID ,
        // coa_id: this.coa.ID == undefined || this.coa.ID == null || this.coa.id == null ? 0 : this.coa.ID ,
        // cost_center_id: this.costCenter.ID == undefined || this.costCenter.id == null ? 0 : this.costCenter.ID ,
        // additional_value: parseFloat(this.additionalValue),
        additional_coa: this.additionalCoa,
      };
      // console.log(params, "params");
      console.log("params 656", params);
      this.$http.post("/api/v1/other-payment/for-dn/lines", params).then((result) => {
        this.$vs.loading.close();
        //   // if(r.stat)
        if (result.code == 500) {
          this.$vs.notify({
            title: "Failed",
            text: result.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "warning",
          });
        } else if (result.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: result.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "success",
          });
        }
        this.$nextTick(() => {
          // this.$emit("closeDetail");
          this.$emit("closeDetail", true);
          this.$vs.loading.close();
        });
      });
    },
    setCoaSelected(id) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/chart-of-account/" + id).then((resp) => {
            if (resp.status == "success") {
            // this.coa = resp.data[0].coa;
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close(); 
            }
        });
    },
    setCostCenterSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/cost-center/" + id).then((resp) => {
        if (resp.status == "success") {
          this.costCenter = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    addAllChecked() {
      if (this.checkedAll == false) {
        for (var i = 0; i < this.dataotherPayment.length; i++) {
          if (this.checked.includes(this.dataPayment[i].ID)) {
            //
          } else {
            this.checked.push(this.dataPayment[i].ID);
            this.dataChecked.push(this.dataPayment[i]);
          }
        }
      } else {
        for (var j = 0; j < this.dataotherPayment.length; j++) {
          this.checked.splice(this.checked.indexOf(this.dataPayment[j].ID), 1);
          this.dataChecked = this.dataChecked.filter((v) => {
            return v.ID != this.dataPayment[j].ID;
          });
        }
      }
    },
    loadInvoice() {
      const invoiceCustomer = dataInvoiceCustomer(
        this.otherPayment.PartnerCode
      );
      invoiceCustomer.then((r) => {
        this.dataPayment = [];
        r.data.invoice.map((row) => {
          this.dataotherPayment.push({
            ...row,
            ...{ paid_now: row.Unpaid, Note: "" },
          });
        });
        console.log(this.dataPayment);
      });
    },
    addField() {
      this.inputs.push({
        additional_value: 0,
        cost_center_id: null,
        coa_id: null,
        ou_id: null,
      })
      this.additionalCoa.push({
        additional_value: 0,
        cost_center_id: null,
        coa_id: null,
        ou_id: null,
      })
      console.log(this.inputs)
    },
    removeField(index) {
      this.inputs.splice(index, 1)
    },
  },
};
</script>