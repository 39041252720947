<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">View Payment</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier</label>
        <multiselect
          class="selectExample"
          v-model="create.supplier"
          :options="optionSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          @search-change="getOptionSupplier"
          :disabled="false"
        >
          <!-- <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </div>
          </template> -->
        </multiselect>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Territory</label>
        <multiselect
          class="selectExample"
          v-model="create.territory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
                dt.option.code + " " + dt.option.name
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                dt.option.code + " " + dt.option.name
              }}</span>
            </div>
          </template>
        </multiselect>
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Payment Method</label>
        <multiselect
          class="selectExample"
          v-model="create.method"
          :options="optionMethod"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
        >
        </multiselect>
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Operating Unit Bank</label>
        <multiselect
          class="selectExample"
          v-model="create.operatingUnitBank"
          :options="optionOperatingUnitBank"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">
                {{ dt.option.BankName + " " + dt.option.AccountNumber }}
                <br />
                {{ dt.option.AccountName }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{ dt.option.BankName + " " + dt.option.AccountNumber }}
                <br />
                {{ dt.option.AccountName }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Date Payment</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Date Payment"
          v-model="create.date_payment"
          placeholder="Date Deposit"
          :disabled-dates="{ from: new Date() }"
        ></datepicker>
        <!-- disabled-dates="{from: new Date()}" -->
      </div>

      <div class="vx-col w-full" v-if="create.method != 'Transfer'">
        <label class="vs-input--label">{{ this.create.method }} Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Date"
          v-model="create.date_giro"
          :placeholder="'Date ' + create.method"
          :disabled-dates="{ from: new Date() }"
        ></datepicker>
      </div>

      <div class="vx-col w-full" v-if="create.method != 'Transfer'">
        <label class="vs-input--label"
          >Number Of {{ this.create.method }}</label
        >
        <vs-input class="w-full" v-model="create.number_of" />
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Posting Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Posting Date"
          v-model="create.date_posting"
          placeholder="Date Deposit"
          :disabled-dates="{ from: new Date() }"
        ></datepicker>
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier Bank</label>
        <multiselect
          class="selectExample"
          v-model="create.supplierBank"
          :options="optionBankSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title"
                >{{ dt.option.BankName }} {{ dt.option.AccountNumber }}
                <br />({{ dt.option.AccountName }})
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{ dt.option.BankName }} {{ dt.option.AccountNumber }} <br />({{
                  dt.option.AccountName
                }})
              </span>
            </div>
          </template>
        </multiselect>
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Note</label>
        <vs-textarea v-model="create.note" />
      </div>

      <div class="vx-row w-full ml-4">
        <vs-input
          class="w-full"
          label="Ref Code"
          name="Ref Code"
          v-model="create.reference_code"
        />
      </div>
      <div class="vx-row w-full ml-4">
        <vs-input
          class="w-full"
          label="Amount"
          name="Amount"
          v-model="create.amount"
          @keyup="create.amount = formatPrice(create.amount.toString())"
        />
      </div>
      <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png .pdf"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"
        >List Attachment</vs-divider
      >
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="tr in fileAttachment">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.ListID"
            >
              <td class="td vs-table--td">{{ tr.NameFile }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="downloadFileAwsS3(tr.PathFile)"
                  />
                </vx-tooltip>
              </td>
              <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click.stop="handleDeleteAttachment(tr.ListID)"
                    />
                  </vx-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <!-- LIST DN -->
      <vs-divider style="width: 100%; margin-left: 2%"
        ><b>List Payment</b></vs-divider
      >
      <vs-table
        stripe
        border
        description
        class="w-full"
        :sst="true"
        :data="table.detail_payment"
      >
        <!-- TAMPILKAN DOCUMENT_REF_COE DAN AMOUNT -->
        <!-- <vs-table stripe border description class="w-full" :sst="true"> -->

        <template slot="thead">
          <!-- <vs-td style="text-align: center;"><b>Payment Account</b></vs-td> -->
          <vs-td style="text-align: center"><b>Type</b></vs-td>
          <vs-td style="text-align: center"><b>Payment Voucher</b></vs-td>
          <vs-td style="text-align: right"><b>Paid</b></vs-td>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="data[indextr].class"
          >
            <vs-td style="text-align: center">
              {{ data[indextr].Type != "Return" ? "Claim" : "Return" }}
            </vs-td>
            <vs-td style="text-align: center">
              {{  data[indextr].Code+ ' - ' + data[indextr].DocumentRefCode  }}
            </vs-td>
            <vs-td style="text-align: right">
              {{ data[indextr].Amount.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
              <!-- .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") -->
            </vs-td>
          </vs-tr>
        </template>
        <br />
      </vs-table>

      <!-- ADD COA, COST CENTER, & ADDITIONAL VALUE -->
        <div class="vx-col w-full" v-if="this.additionalValue !=0">
          <vs-input
          class="w-full input-readonly"
          label="COA"
          name="COA"
          v-model="coa"
          readonly
        />
        </div>

        <div class="vx-col w-full" v-if="this.additionalValue !=0">
          <vs-input
          class="w-full input-readonly"
          label="Cost Center"
          name="Cost Center"
          v-model="costCenter"
          readonly
        />
        </div>
    
        <div class="vx-col w-full" v-if="this.additionalValue !=0">
          <label class="vs-input--label" >Additional Value</label>
          <vs-input class="w-full input-readonly"  v-model="additionalValue" readonly 
          @keyup="additionalValue = formatPrice(additionalValue.toString())" />
          
        </div>

      <div class="vx-col w-full">
        <br />
        <vs-button class="mb-2" v-on:click="handleSubmit"
          >Update Note</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  props: {
    selected: Object,
    option: Object,
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        lengthFile: 0,
        file: [],
        fileAttachment: [],
        search: "",
        supplier: null,
        operatingUnitBank: null,
        supplierBank: null,
        additionalValue: 0,
        costCenter: null,
        coa: null,
        optionCostCenter: [],
        optionCoa: [],
        bank: [],
        paymentProposal: [],
        optionSupplier: [],
        optionBankSupplier: [],
        optionPaymentProposal: [],
        optionTerritory: [],
        optionOperatingUnitBank: [],
        optionMethod: ["Giro", "Cheque", "Transfer"],
        table: {
          data: [],
        },
        create: {
          supplier: null,
          operatingUnitBank: null,
          method: "Giro",
          territory: null,
          reference_code: "",
          date_payment: "",
          date_giro: "",
          date_posting: "",
          amount: 0,
          number_of: "",
        },
      };
    },
    getGeneralForm() {
      this.$http
        .get("/api/v1/other-payment/for-dn/form-general/0")
        .then((r) => {
          // this.optionTerritory = r.data.territory
          
          this.optionTerritory = r.data.territories;
          this.optionTerritory.map((v) => {
            if (this.selected.TerritoryID == v.ID) {
              this.create.territory = v;
            }
          });
          // this.optionBank = r.data.bank
          // this.optionOperatingUnitBank = r.data.operatingUnitBank
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getOptionSupplierBank() {
      this.$http
        .get("/api/v1/pi/supplier-account/" + this.selected.PartnerID)
        .then((r) => {
          console.log(this.selected, " selected sini ---");
          this.optionBankSupplier = r.data.account;
          this.optionBankSupplier.map((v) => {
            console.log(
              this.selected,
              " selected sini -----",
              v.BankID == this.selected.SupplierBankID,
              v.AccountNumber == this.selected.SupplierAccountNumber
            );
            if (
              v.BankID == this.selected.SupplierBankID &&
              v.AccountNumber == this.selected.SupplierAccountNumber
            ) {
              this.create.supplierBank = v;
            }
          });
          console.log(r);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getData() {
      this.$vs.loading();
      console.log("GET DATA")
      this.$http
        .get("/api/v1/other-payment-line/" + this.selected.ID)
        .then((resp) => {
          // this.$vs.loading.close();
          if (resp.code == 200) {
           
            // if (resp.data.unpaid_value == 0) {
            // this.$vs.notify({
            //   color: "success",
            //   title: "Success",
            //   position: "top-right",
            //   iconPack: "feather",
            //   icon: "icon-x-circle",
            // });
            // this.handleClose();
            // return
            // }
            this.table.detail_payment = resp.data;
            console.log("resp.data", resp.data)
            // this.table.payment = resp.data.payment;
            // this.table.data_payment = resp.data.detail_payment;
            // console.log("resp.data.detail_payment", resp.data.detail_payment);
             this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    removeSelectedDN(...args) {
      for (var i = 0; i < this.debitNote.length; i++) {
        console.log(args[0].ID, this.applyID[i]);
        if (args[0].ID == this.applyID[i]) {
          this.applyID.splice(i, 1);
          this.applyValue.splice(i, 1);
          break;
        }
      }
      console.log(args, "args");

      this.maxSelectedDN = 999;
    },
    processSelectedDN(json) {
      this.$loa;
      console.log(json);
      json.map((v, r) => {
        if (r > 0 && v.length > 0) {
          const filter = this.optionDebitNote.filter((subv) => {
            return v[0] == subv.Code;
          });
          if (filter.length > 0) {
            const unUsed =
              parseFloat(filter[0].TotalDnValue) -
              parseFloat(filter[0].UsedDnValue);
            console.log(unUsed, v[2]);
            if (unUsed >= parseFloat(v[2])) {
              const checkSelected = this.debitNote.filter((subv) => {
                return v[0] == subv.Code;
              });
              if (checkSelected.length < 1) {
                this.debitNote.push(filter[0]);
                this.applyID.push(filter[0].ID);
                if (this.applyValue.length < this.debitNote.length) {
                  this.applyValue.push(parseFloat(v[2]));
                } else {
                  this.applyValue[this.applyValue.length - 1] = parseFloat(
                    v[2]
                  );
                }
              } else {
                this.$vs.notify({
                  color: "warning",
                  title: "Information",
                  text: v[0] + " already selected",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            } else {
              this.$vs.notify({
                color: "warning",
                title: "Information",
                text: v[0] + " value greater than remaining debit note",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          } else {
            this.$vs.notify({
              color: "warning",
              title: "Information",
              text: v[0] + " not found",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          console.log(filter);
          console.log(v, r, "r");
        }
      });
      // this.optionDebitNote.map(item => {
      //   // console.log(item, 'item')
      // })
    },
    handleSubmit() {
      const params = {
        id: this.selected.ID,
        note: this.create.note,
      };
      console.log(params);
      this.$http
        .put("/api/v1/other-payment/for-dn/update-note", params)
        .then((result) => {
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.handleClose();
          } else {
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {});
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("closeDetail", true);
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.lengthFile = this.$refs.file.files.length;
        this.file = this.$refs.file.files;
        // for(let i =0; i < this.$refs.file.files.length; i++) {

        this.uploadData(0);
        // }
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    uploadData(array) {
      console.log(this.lengthFile, this.file, array);
      if (this.lengthFile <= array) {
        // this.$emit("closeDetail", true);
        // this.$vs.loading.close();
        document.querySelector("#fileInput").value = "";
        return;
      }
      this.$vs.loading();
      this.$http
        .post("/api/v1/other-payment/upload-file", this.paramUpload(array), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.fileAttachment = [];
            resp.data.attachment.map((v) => {
              this.fileAttachment.push({
                ListID: v.ListID,
                NameFile: v.NameFile,
                PathFile: v.PathFile,
              });
            });
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.uploadData(++array);
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
          this.uploadData(++array);
        });
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.selected.ID);
      form.append("file", this.file[array]);
      return form;
    },
    handleDeleteAttachment(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("list_id", id);
      form.append("id", this.selected.ID);
      this.$http
        .post("/api/v1/other-payment/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.fileAttachment = this.fileAttachment.filter((v) => {
              return v.ListID != id;
            });
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    getBankForm() {
      this.$http
        .get(
          "/api/v1/other-payment/for-dn/form-general/" +
            this.selected.TerritoryID
        )
        .then((r) => {
          this.optionOperatingUnitBank = r.data.operatingUnitBank;
          this.optionOperatingUnitBank.map((v) => {
            if (v.AccountNumber == this.selected.BankAccountNumber) {
              this.create.operatingUnitBank = v;
            }
          });
        })
        .catch((e) => {});
    },
    getCoa() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/chart-of-accounts", {
          params: {
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            var _this = this;
            resp.data.records.forEach(dt =>{
              if (dt.ID == this.selected.ChartOfAccountID){
                _this.coa =   dt.Code + " - " + dt.Name ;
                }
              });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Coa option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getCostCenter() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/cost-center", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            var _this = this;
            this.optionCostCenter = resp.data.records;
            console.log(resp.data.records, "CC")

            resp.data.records.forEach(dt =>{
              if (dt.ID == this.selected.CostCenterID){
                _this.costCenter =  dt.Code + " - " + dt.Name;
                }
              });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Cost Center option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },

    getOptionSupplier(query) {
      if (query.length <= 2) {
        return;
      }
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
            search: query,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
            this.optionSupplier.map((v) => {
              if (v.ID == this.selected.PartnerID) {
                this.create.supplier = v;
              }
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
  },
  mounted() {
    console.log("mounted",this.selected)
    this.getGeneralForm();
    this.getData();
    this.create.method = this.selected.PaymentMethodDesc;
    this.create.reference_code = this.selected.ReferenceCode;
    this.create.amount = this.formatPrice(this.selected.Amount);
    console.log(this.selected.DatePayment);
    this.create.date_payment = this.selected.DatePayment;
    this.create.date_giro = this.selected.GiroDate;
    this.create.number_of = this.selected.GiroNumber;
    this.create.date_posting = this.selected.DatePosting;
    this.fileAttachment = this.selected.Attachment;
    this.create.note = this.selected.Note;
    this.additionalValue = this.formatPrice(this.selected.AdditionalValue);
    this.getOptionSupplier(this.selected.PartnerName);
    this.getBankForm();
    this.getOptionSupplierBank();
    this.getCostCenter();
    this.getCoa();
    
    // this.coa = this.getOptionCoa();
    // this.costCenter = this.selected.CostCenterID;
    
    

  },
  computed: {},
  watch: {
    
    "selected.PartnerID"() {
      // console.log(this.create.supplier)
    },
    // "create.territory.ID"() {
    //   this.getBankForm()
    // },
    "selected.ID"() {
      console.log(this.selected, "this.selected WATCH")
      this.getData();
      this.getCostCenter();
      this.getCoa();
      this.getOptionSupplier(this.selected.PartnerName);
      this.getOptionSupplierBank();
      this.getBankForm();
      this.create.method = this.selected.PaymentMethodDesc;
      this.create.reference_code = this.selected.ReferenceCode;
      this.create.amount = this.formatPrice(this.selected.Amount);
      this.create.date_payment = this.selected.DatePayment;
      this.fileAttachment = this.selected.Attachment;
      this.create.date_giro = this.selected.GiroDate;
      this.create.number_of = this.selected.GiroNumber;
      this.create.date_posting = this.selected.DatePosting;
      this.create.note = this.selected.Note;
      this.coa = this.selected.coa;
      this.costCenter = this.selected.costCenter;
      this.additionalValue = this.formatPrice(this.selected.AdditionalValue);
      
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>